import React from 'react';
import './Cards.css';
import CardItem from './CardItem';

function Cards() {
  return (
    <div className='media'>
      <h1>MEDIA</h1>
      <div className='line'></div>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            <CardItem
              src='images/media_img_1.jpg'
              text='Miracles in Rosary - Be blessed through Mother Mary'
              label='Miracles In Rosary'
              path='/youtubevideos'
            />
            <CardItem
              src='images/media_img_2.jpeg'
              text='Praise and Worship - Worshipping Lord with all your heart'
              label='Praise and Worship'
              path='/youtubevideos'
            />
          </ul>
          <ul className='cards__items'>
            <CardItem
              src='images/media_img_3.jpg'
              text='Testimonials of people who experienced Gods love'
              label='Testimony'
              path='/youtubevideos'
            />
            <CardItem
              src='images/media_img_4.jpg'
              text='Listen to Heavenly songs - Anointing songs'
              label='Songs'
              path='/youtubevideos'
            />
            <CardItem
              src='images/media_img_5.jpg'
              text='Our Publications'
              label='Magazine'
              path='/magazine'
            />
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Cards;
