import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import './VideoCard.css';
import { Link } from 'react-router-dom';

const VideoCard = ({videoId, image, title, channel, views, timestamp, channelImage}) => {
  console.log(videoId)
    return (
      <Link className='videocard__link' style={{ textDecoration: 'none' }} to = {{pathname : '/services', state : { videoId: videoId }}}>
        <div className='videocard'>
          <img className='videocard__image' src={image} alt='' />
          <div className="videocard__info">
            <div className="videocard__text">
              <h4>{title}</h4>
            </div> 
          </div>
        </div>
        </Link>
    )
}

export default VideoCard;
