import ImageGallery from 'react-image-gallery';
import React from 'react';


const images = [
  {original: 'https://firebasestorage.googleapis.com/v0/b/sij-dev.appspot.com/o/festival%2Fgallery_19.png?alt=media&token=4cc0618a-3259-47dd-b2ca-fa70df76d9a8',
   thumbnail: 'https://picsum.photos/id/1018/250/150/'
  },
  {original: 'https://firebasestorage.googleapis.com/v0/b/sij-dev.appspot.com/o/festival%2Fgallery_18.png?alt=media&token=393eddde-ac63-4c46-8cdd-f942bc5f1403',
   thumbnail: 'https://picsum.photos/id/1018/250/150/'
  },
  {
    original: 'https://firebasestorage.googleapis.com/v0/b/sij-dev.appspot.com/o/festival%2Fgallery_1.png?alt=media&token=5feaf223-e556-4d5c-92fe-dba57d17eaaf',
    thumbnail: 'https://picsum.photos/id/1018/250/150/',
  },
  {
    original: 'https://firebasestorage.googleapis.com/v0/b/sij-dev.appspot.com/o/festival%2Fgallery_2.png?alt=media&token=f8ad7e41-2323-4dcb-aaa3-85df0c8433b6',
    thumbnail: 'https://picsum.photos/id/1015/250/150/',
  },
  {
    original: 'https://firebasestorage.googleapis.com/v0/b/sij-dev.appspot.com/o/festival%2Fgallery_3.png?alt=media&token=be06de63-5baa-4b29-92f5-f0b68ca5a096',
    thumbnail: 'https://picsum.photos/id/1019/250/150/',
  },
  {
    original: 'https://firebasestorage.googleapis.com/v0/b/sij-dev.appspot.com/o/festival%2Fgallery_4.png?alt=media&token=7ccba35f-eaa8-43d6-ab24-36d7da7af9ad',
    thumbnail: 'https://picsum.photos/id/1018/250/150/',
  },
  {
    original: 'https://firebasestorage.googleapis.com/v0/b/sij-dev.appspot.com/o/festival%2Fgallery_5.png?alt=media&token=f367aed8-a0e8-4150-a1e4-41701e2379ae',
    thumbnail: 'https://picsum.photos/id/1015/250/150/',
  },
  {
    original: 'https://firebasestorage.googleapis.com/v0/b/sij-dev.appspot.com/o/festival%2Fgallery_6.png?alt=media&token=08e36594-2684-4fce-91e1-fc22e939ac80',
    thumbnail: 'https://picsum.photos/id/1019/250/150/',
  },
  {
    original: 'https://firebasestorage.googleapis.com/v0/b/sij-dev.appspot.com/o/festival%2Fgallery_7.png?alt=media&token=41eb73d6-4462-4244-81a7-e7c8ba483090',
    thumbnail: 'https://picsum.photos/id/1019/250/150/',
  },

  {
    original: 'https://firebasestorage.googleapis.com/v0/b/sij-dev.appspot.com/o/festival%2Fgallery_8.png?alt=media&token=d3c1b061-8eb0-41c3-b431-6e90765b252d',
    thumbnail: 'https://picsum.photos/id/1018/250/150/',
  },
  {
    original: 'https://firebasestorage.googleapis.com/v0/b/sij-dev.appspot.com/o/festival%2Fgallery_9.png?alt=media&token=287e993b-fd91-448a-b7ef-073ace986e0c',
    thumbnail: 'https://picsum.photos/id/1015/250/150/',
  },
  {
    original: 'https://firebasestorage.googleapis.com/v0/b/sij-dev.appspot.com/o/festival%2Fgallery_11.png?alt=media&token=984b8d68-6274-4dfd-8e3e-ece6bae018cb',
    thumbnail: 'https://picsum.photos/id/1019/250/150/',
  },
  {
    original: 'https://firebasestorage.googleapis.com/v0/b/sij-dev.appspot.com/o/festival%2Fgallery_10.png?alt=media&token=b2a6bb87-eae4-4f00-a915-a16732440cf9',
    thumbnail: 'https://picsum.photos/id/1018/250/150/',
  },
  {
    original: 'https://firebasestorage.googleapis.com/v0/b/sij-dev.appspot.com/o/festival%2Fgallery_13.png?alt=media&token=9d7b1cfd-ae73-4bb3-b0c1-65fca0b5e2f7',
    thumbnail: 'https://picsum.photos/id/1015/250/150/',
  },
  {
    original: 'https://firebasestorage.googleapis.com/v0/b/sij-dev.appspot.com/o/festival%2Fgallery_14.png?alt=media&token=13b89675-1336-4e2f-9ea0-23aefdf531a6',
    thumbnail: 'https://picsum.photos/id/1019/250/150/',
  },
  {
    original: 'https://firebasestorage.googleapis.com/v0/b/sij-dev.appspot.com/o/festival%2Fgallery_15.png?alt=media&token=aaf07b1b-c644-41ec-a5bf-abe9c970d8f0',
    thumbnail: 'https://picsum.photos/id/1019/250/150/',
  },
];

class MyGallery extends React.Component {
  render() {
    return <ImageGallery items={images} showThumbnails={false} />;
  }
}

export default MyGallery;
