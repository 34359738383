import React from 'react';
import './FestivalHighlightItem.css';
import Mixpanel from 'mixpanel-browser';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faPlay } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


function handleGoogleMapClick(event) {
  Mixpanel.track('Google map link click', { button: 'Google-Map' });
}

const FestivalHighlightItem = ({ onClick, title, subTitle, image, ytVideoId }) => {

  library.add(faPlay);
  return (
    <div onClick={onClick} className='festival_highlight_item'>
      <div className='video-container'>
      <img className='festival_highlight_item__image' src={image} alt='' />
      <button className='play-button'>
      <FontAwesomeIcon className="icon" icon="play" color='black'/>
      </button>
      </div>
        <div className="festival_highlight_item__text">
          <div className='festival_highlight_title'>
            <h4>{title}</h4>
            <p> {subTitle}</p>
          </div>
        </div>
    </div>
  )
}

export default FestivalHighlightItem;
