import React from 'react';
import '../../App.css';
import { useLocation } from 'react-router-dom'
import YoutubeEmbed from './media/YoutubeEmbed';

export default function Services() {

  const location = useLocation()
  const { videoId } = location.state
  console.log(videoId)

  return <YoutubeEmbed embedId= {videoId} /> ;
}
