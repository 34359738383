import React from 'react';
import '../../../App.css';
import { Button } from '../../Button';
import './FestivalHome.css';
import { Link } from 'react-router-dom';
import FestivalHighlights from './FestivalHighlights';

function FestivalHome() {
  return (
    <div className='festival-container'>
      <FestivalHighlights/>
    </div>
  );
}

export default FestivalHome;
