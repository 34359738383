import React from "react";
import "./Podcasts.css";
import { Link } from 'react-router-dom';

function Podcasts() {
  return (
      <div className="podcastsDiv">
        <div className="backgroundColorDiv"></div>
        <div className="contentDiv">
        <h2 className="podcastPretitle">
          "Sound of Heaven"
          </h2>
          <h2 className="heading">Podcasts</h2>
          <h2 className="about">
          Here are the Words of Anointing and Deliverance.
          </h2>
          <h3 className="about_second">
          Let's listen now as audio 🎧  without any interruption.
          </h3>
          <div className="button1" onClick={() => window.open("https://podcasts.google.com/feed/aHR0cHM6Ly9hbmNob3IuZm0vcy9kOWMxOWVlOC9wb2RjYXN0L3Jzcw?sa=X&ved=2ahUKEwiM_5C6l7v9AhWkyKACHSgyDu4Q9sEGegQIARAC", '_blank')}  ></div>
          <img className="button2" onClick={() => window.open("https://open.spotify.com/show/6t4dhN8SuIGFCs6leCpeWi", '_blank')}></img>
        </div>
      </div>
  );
}

export default Podcasts;
