import React, {useEffect, useState} from 'react';
import VideoCard from './../../VideoCard/VideoCard';
import './YoutubeVideos.css';
import axios from 'axios';
import {DateTime} from 'luxon';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import '../../../App.css';
import { useLocation } from 'react-router-dom'
import Mixpanel from 'mixpanel-browser';


const YoutubeVideos = () => {


  class CustomVideoItem {
    constructor(videoId, title, description, thumbnailUrl, channelTitle, publishTime) {
      this.id = { videoId };
      this.snippet = {
        title,
        description,
        thumbnails: {
          medium: { url: thumbnailUrl }
        },
        channelTitle,
        publishTime
      };
    }
  }

  const { mr1, mr2, mr3, mr4, mr5, tm1, tm2, tm3 } = getAdditionalVideos(CustomVideoItem);
  

    const [videoCards, setVideoCards] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);

    const location = useLocation()
    const { searchkey } = location.state
    console.log(searchkey)

    useEffect(() => {
      Mixpanel.init('312ba53aa81ecfff896426fb2d09e86a');
      Mixpanel.track('Media-'+searchkey);
    }, []);

    useEffect(() => {
      axios
      .get(`https://www.googleapis.com/youtube/v3/search?key=AIzaSyB6BpQ9gGPbd1jkTZRyy7qEPnHsF-x4iaM&channelId=UC3Z4HFMS9RVuL37QfjcQZEA&part=snippet,id&order=relevance&maxResults=20&q=${searchkey}`)
        .then(response => {
          console.log(response.data.items)
          if(searchkey == 'Miracles In Rosary') {
            response.data.items.push(mr1, mr2, mr3, mr4, mr5)
          } else if(searchkey == 'Testimony') {
            response.data.items.push(tm1, tm2, tm3)
          }
          const sortedData = response.data.items.sort((a, b) => {
            return new Date(b.snippet.publishTime) - new Date(a.snippet.publishTime);
          });
          createVideoCards(sortedData);
        })
        .catch(error => {
          console.log(error);
          showCachedVideoDataOnFail(searchkey, mr1, mr2, mr3, mr4, mr5, tm1, tm2, tm3, createVideoCards);
          //setIsError(true);
        })
    }, [])

    async function createVideoCards(videoItems) {
      let newVideoCards = [];
      for (const video of videoItems) {
        const videoId = video.id.videoId;
        const snippet = video.snippet;
        const channelId = snippet.channelId;
        const title = snippet.title;
        const image = snippet.thumbnails.medium.url;
        const views = 1;
        const timestamp = DateTime.fromISO(snippet.publishedAt).toRelative();
        const channel = snippet.channelTitle;
        const channelImage = "";

        newVideoCards.push({
          videoId,
          image,
          title,
          channel,
          views,
          timestamp,
          channelImage
        });
      };
      setVideoCards(newVideoCards);
      setIsLoading(false);
    }

    if(isError) {
      return <Alert severity="error" className='loading'>No Results found!</Alert>
    }
    return (
        
        <div className='recommendedvideos'>
          <h2>{searchkey}</h2>
            { isLoading ? <CircularProgress className='loading' color='secondary' /> : null }
            <div className="recommendedvideos__videos">
                {
                  videoCards.map(item => {
                    return (
                        <VideoCard videoId={item.videoId}
                            title={item.title}
                            image={item.image}
                            views={item.views}
                            timestamp={item.timestamp}
                            channel={item.channel}
                            channelImage={item.channelImage}
                        />
                    )
                  })
                }
            </div>
        </div>
    )


    function getAdditionalVideos(CustomVideoItem) {
      const mr1 = new CustomVideoItem(
        "f1t9UV4A9ZA",
        "Miracles in Rosary || Swindon UK || Part-2",
        "Led by Br. Zachariah Tom & Tom Zachariah other Channels",
        "https://i.ytimg.com/vi/f1t9UV4A9ZA/mqdefault.jpg",
        "MIRACLES IN ROSARY",
        "2023-04-08T08:07:55Z"
      );
    
      const mr2 = new CustomVideoItem(
        "kp2dq_KAric",
        "Miracles in Rosary || Swindon UK || Part-1",
        "Led by Br. Zachariah Tom & Tom Zachariah other Channels",
        "https://i.ytimg.com/vi/kp2dq_KAric/mqdefault.jpg",
        "MIRACLES IN ROSARY",
        "2023-04-08T08:07:52Z"
      );
    
      const mr3 = new CustomVideoItem(
        "Ux8WS6uQrxQ",
        "Miracles in Rosary || Swindon UK || Part-3",
        "Led by Br. Zachariah Tom & Tom Zachariah other Channels",
        "https://i.ytimg.com/vi/Ux8WS6uQrxQ/mqdefault.jpg",
        "MIRACLES IN ROSARY",
        "2023-04-08T08:07:58Z"
      );
    
      const mr4 = new CustomVideoItem(
        "FwO37Xwct4s",
        "Miracles in Rosary - Goa (2nd July 2022) Part 01",
        "Miracles in Rosary - Goa (2nd July 2022) Part 01",
        "https://i.ytimg.com/vi/FwO37Xwct4s/mqdefault.jpg",
        "MIRACLES IN ROSARY",
        "2023-03-08T15:45:47Z"
      );
    
      const mr5 = new CustomVideoItem(
        "sS9RfmjI7GM",
        "Miracles in Rosary - Goa (2nd July 2022) Part 02",
        "Miracles in Rosary - Goa (2nd July 2022) Part 02",
        "https://i.ytimg.com/vi/sS9RfmjI7GM/mqdefault.jpg",
        "MIRACLES IN ROSARY",
        "2023-03-14T14:47:05Z"
      );

      const tm1 = new CustomVideoItem(
        "Ne5Bfs6CAgI",
        "Spiritism is Dangerous - Testimony, Mumbai",
        "Spiritism is Dangerous - Testimony, Mumbai",
        "https://i.ytimg.com/vi/Ne5Bfs6CAgI/mqdefault.jpg",
        "TESTIMONIALS",
        "2023-01-23T05:15:31Z"
      );
    
      const tm2 = new CustomVideoItem(
        "AnjNENZj644",
        "FIBROMYALGIA HEALED...! || Testimony of Sangeetha.",
        "FIBROMYALGIA HEALED...! || Testimony of Sangeetha.",
        "https://i.ytimg.com/vi/AnjNENZj644/mqdefault.jpg",
        "TESTIMONIALS",
        "2023-01-25T09:35:27Z"
      );
    
      const tm3 = new CustomVideoItem(
        "HTWbosl2QjM",
        "Miracles in Rosary || Swindon UK || Testimonials",
        "Miracles in Rosary || Swindon UK || Testimonials",
        "https://i.ytimg.com/vi/HTWbosl2QjM/hqdefault.jpg",
        "MIRACLES IN ROSARY",
        "2023-04-08T08:07:58Z"
      );

      return { mr1, mr2, mr3, mr4, mr5, tm1, tm2, tm3 };
    }
}

export default YoutubeVideos;

function showCachedVideoDataOnFail(searchkey, mr1, mr2, mr3, mr4, mr5, tm1, tm2, tm3, createVideoCards) {
  const items = [];
  if (searchkey == 'Miracles In Rosary') {
    items.push(mr1, mr2, mr3, mr4, mr5);
  } else if (searchkey == 'Testimony') {
    items.push(tm1, tm2, tm3);
  }
  const sortedData = items.sort((a, b) => {
    return new Date(b.snippet.publishTime) - new Date(a.snippet.publishTime);
  });
  createVideoCards(sortedData);
}

