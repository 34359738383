import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import './UpcomingEventItem.css';
import { GrLocation } from "react-icons/gr"
import { MdAccessTime } from "react-icons/md"
import Mixpanel from 'mixpanel-browser';


function handleGoogleMapClick(event) {
  Mixpanel.track('Google map link click', { button: 'Google-Map' });
}

const UpcomingEventItem = ({ image, title, location, date, mapLocation }) => {
  const dateData = String(date).split(' ');
  const month = dateData[0]
  const day = dateData[1]
  const year = dateData[2]

  return (
    <div className='upcoming_event_item'>
      <img className='upcoming_event_item__image' src={image} alt='' />
      <div className="upcoming_event_item__info">
        <div className="upcoming_event_item__text">
          <div className='upcoming_event_date'>
            <h4> {month}</h4>
            <h1>{day}</h1>
            <h5> {year}</h5>
          </div>
          <div className='upcoming_event_title'>
            <h4>{title}</h4>
            <p> {location}</p>
            <a href= {mapLocation} onClick={handleGoogleMapClick} target="_blank">Find in Google Map </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UpcomingEventItem;
