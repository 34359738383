import React, { useState, useEffect } from "react";
import TableOfContents from "../aboutus/TableOfContents";
import YouTube from 'react-youtube';
import Mixpanel from 'mixpanel-browser';
import axios from 'axios';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';


const DynamicBlog = () => {

    useEffect(() => {
        Mixpanel.init('312ba53aa81ecfff896426fb2d09e86a');
        Mixpanel.track('About-SIJ-PageView');
      }, []);
    

    const [showFullText, setShowFullText] = useState(false);
    const [blogData, setBlogData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);


    const handleReadMore = () => {
        setShowFullText(true);
    };

    const startSeconds = 147; // start at 30 seconds
   
    const opts = {
      height: '100%',
      width: '100%',
      playerVars: {
        autoplay: 0,
        start: startSeconds,
      },
    };
    const opts_gen = {
        height: '80%',
        width: '100%',
        playerVars: {
          autoplay: 0,
          start: 0,
        },
      };


    useEffect(() => {
        axios
          .get(`https://sample-959f8.firebaseio.com/blogs.json`)
          .then(response => {
            console.log(response.data[0])
            const parsedData = response.data.map((blog) => {
                return {
                  title: blog.title,
                  cover_image: blog.cover_image,
                  subtitile: blog.subtitle,
                  video_id: blog.video_id,
                  highlighted_quote: blog.highlighted_quote,
                  imageUrl: blog.imageUrl,
                  introduction: blog.introduction,
                  sections: blog.sections.map((section) => {
                    return {
                      title: section.title,
                      image: section.image,
                      subtitle: section.subtitle,
                      text: section.text,
                      video_id: section.video_id,
                      highlighted_quote: section.highlighted_quote
                    };
                  })
                };
              });
            setBlogData(parsedData);
            setIsError(false);
            setIsLoading(false);
          })
          .catch(error => {
            console.log(error);
            setIsError(true);
          })
    }, [])

    if(isError) {
        return <Alert severity="error" className='loading'>No Results found!</Alert>
    }

    if (!blogData[0]) {
        return <div>No blog data available.</div>;
    }
    
    const firstBlog = blogData[0];

    return (
        <div className="blog-container">
            { isLoading ? <CircularProgress className='loading' color='secondary' /> : null }
            <header>
            <img src = {blogData[0].cover_image} width= "auto" height= "300"/>          
            </header>
            <main>
                <section className="left-section">
                {/* <TableOfContents className="blog-toc-mob"/> */}
                 
                 {/* introduction */}
                    <article id="introduction">
                        <header>
                            <h2>{blogData[0].title}</h2>
                            <h6>{blogData[0].subtitle}</h6>
                        </header>
                        {blogData[0].imageUrl != "" ? <div className="image-container">
                            <img src={blogData[0].imageUrl} alt="blog post" />
                        </div> : null}
                        <br></br>
                        <p>{blogData[0].introduction}</p>
                        <br></br>
                        <blockquote className="multi-color-quote">
                            <span className="blue">{blogData[0].highlighted_quote}</span>
                            {/* <span className="red"> I read it: SPIRIT IN JESUS.</span> */}
                        </blockquote>
                        <div className="video-container">
                            <YouTube videoId={blogData[0].video_id} opts={opts} />
                        </div>
                    </article>;

                 {blogData[0].sections.map((section, index) => (
                      blogContent(section)
                 ))}
                    
                </section>
                <aside className="right-section">
                    {/* <TableOfContents className="blog-toc-desktop"/> */}
                    <div>
                        <h2>Author</h2>
                        <div className="image-container">
                            <img src="https://firebasestorage.googleapis.com/v0/b/sij-dev.appspot.com/o/about_us%2Fbrother_author.png?alt=media&token=db50715d-f38e-4e38-b58c-29bfda6cfaef" alt="author" />
                        </div>
                        <p className="right-section-subtext">Brother Tom Zachariah</p>
                    </div>
                    <div>
                        <h3>Popular Post</h3>
                        <div className="image-container">
                        <YouTube videoId="GWEb9Tu36Oo" opts={opts_gen}/>
                        </div>
                        <div className="image-container">
                        <YouTube videoId="iQhEvdLj8Y0" opts={opts_gen}/>
                        </div>
                        <div className="image-container">
                        <YouTube videoId="cMRugMEnvPs" opts={opts_gen}/>
                        </div>
                    </div>
                    {/* <div>
                        <h3>Follow Me</h3>
                        <p className="right-section-subtext">Some text...</p>
                    </div> */}
                </aside>
            </main>
        </div>
    );

    function blogContent(section) {
        return <article id="nameFromHeaven">
            <header>
                <h2>{section.title}</h2>
                <h6>{section.subtitle}</h6>
            </header>
            {section.image != "" ?  <div className="image-container">
                <img src={section.image} alt="blog post" />
            </div> : null}
            <blockquote className="multi-color-quote">
                <span className="blue">{section.highlighted_quote}</span>
                {/* <span className="red"> I read it: SPIRIT IN JESUS.</span> */}
            </blockquote>
            <p>{section.text}</p>
            <br></br>
            {section.video_id != "" ?  <div className="video-container">
                    <YouTube videoId={section.video_id} opts={opts}/>
            </div> : null}
           
        </article>;
    }

};

export default DynamicBlog;
