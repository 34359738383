import React, { useState } from 'react';
import './PrayerRequest.css';
import axios from 'axios';

function PrayerRequest() {
	const [name, setName] = useState('');
	const [country, setCountry] = useState('');
	const [email, setEmail] = useState('');
	const [phone, setPhone] = useState('');
	const [request, setRequest] = useState('');

	// function to update state of name with
	// value enter by user in form
	const handleChange = (e) => {
		setName(e.target.value);
	}
	// function to update state of age with value
	// enter by user in form
	const handleCountryChange = (e) => {
		setCountry(e.target.value);
	}
	// function to update state of email with value
	// enter by user in form
	const handleEmailChange = (e) => {
		setEmail(e.target.value);
	}
	// function to update state of password with
	// value enter by user in form
	const handlePhoneChange = (e) => {
		setPhone(e.target.value);
	}
	// function to update state of confirm password
	// with value enter by user in form
	const handleRequestChange = (e) => {
		setRequest(e.target.value);
	}
	// below function will be called when user
	// click on submit button .
	const handleSubmit = (e) => {

		// display alert box with user
		// 'name' and 'email' details .
		alert('Your prayer request submitted.');

		axios.post('https://spiritinjesus-21cb4-default-rtdb.firebaseio.com/api/data/prayerRequests.json', {
			name: name,
			country: country,
			email: email,
			request: request
		})
			.then(function (response) {
				console.log(response);
				document.getElementById("prayer_form").reset();
				this.setState({
					name: ""
				  });
			})
			.catch(function (error) {
				console.log(error);
			});
		e.preventDefault();

	}
	return (
		<div className="prayer_request">
			<header className="prayer_request-header">
				<form id="prayer_form" onSubmit={(e) => { handleSubmit(e) }}>
					{/*when user submit the form , handleSubmit()
		function will be called .*/}
					<div className="prayer_request_body">
						<h1>PRAYER REQUEST</h1>
						<div className='line'></div><br></br>
						<h4>“And I tell you more: whenever two of you on earth agree about anything you pray for, it will be done for you by my Father in heaven. For where two or three come together in my name, I am there with them.” Matthew 18:19-20 </h4>
						<br />
						<label >
							Name:
						</label><br />
						<input class= "form_input" type="text" value={name} required onChange={(e) => { handleChange(e) }} /><br />
						{ /*when user write in name input box , handleChange()
			function will be called. */}
						<br />
						<label >
							Country:
						</label><br />
						<input type="prayer_request_text" class= "form_input" value={country} required onChange={(e) => { handleCountryChange(e) }} /><br />
						{ /*when user write in age input box , handleAgeChange()
			function will be called. */}
						<br />
						<label>
							Email:
						</label><br />
						<input type="email" class= "form_input" value={email} required onChange={(e) => { handleEmailChange(e) }} /><br />
						{/* when user write in email input box , handleEmailChange()
			function will be called.*/}
						<br />
						<label>
							Phone Number:
						</label><br />
						<input type="phone" class= "form_input" value={phone} required onChange={(e) => { handlePhoneChange(e) }} /><br />
						{/* when user write in password input box ,
				handlePasswordChange() function will be called.*/}
						<br />
						<label>
							Prayer Request:
						</label><br />
						<textarea type="prayer_request_text_content" class= "form_input_request" value={request} required onChange={(e) => { handleRequestChange(e) }} /><br />
						{/* when user write in confirm password input box ,
					handleConfPasswordChange() function will be called.*/}

						<br />
						<input type="submit" value="Submit" className="prayer_submit" size="50" />
						</div>
				</form>
			</header>
		</div>
	);
}

export default PrayerRequest;
