import React from 'react';
import '../../App.css';
import Cards from '../Cards';
import HeroSection from '../HeroSection';
import Footer from '../Footer';
import Magazines from './magazines/Magazines';
import Testimonials from './testimonials/Testimonials';
import PrayerRequest from './prayerRequest/PrayerRequest';
import Podcasts from './podcast/Podcasts';
import FestivalHighlights from './festivalHighlights/FestivalHighlights';
import Articles from './articles/Articles';
import UpcomingEventsUpdated from './upcomingEvents/UpcomingEventsUpdated';
import SubscribeBanner from '../subscribe/subscribebanner';


function Home() {
  return (
    <>
      <HeroSection />
      <FestivalHighlights/>
      <SubscribeBanner/>
      <UpcomingEventsUpdated />
      <Articles/>
      <Podcasts/>
      <Cards />
      <Testimonials />
      <Magazines />
    </>
  );
}

export default Home;
