import React, {useEffect, useState} from 'react';
import './FestivalHighlights.css';
import axios from 'axios';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import FestivalHighlightItem from './FestivalHighlightItem'
import '../../../App.css';
import { useHistory } from "react-router-dom";
import Mixpanel from 'mixpanel-browser';
import VideoPopup from './VideoPopup';




const FestivalHighlights = () => {

  const [popupOpen, setPopupOpen] = useState(false);

  const openPopup = () => {
    setPopupOpen(true);
  };

  const closePopup = () => {
    setPopupOpen(false);
  };

    const [videoCards, setVideoCards] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    const [youtubeVideoId, setYoutubeVideoId] = useState();

    const history = useHistory();

    const handleVideoClick = (videoId) => {
      Mixpanel.track('Greatest-Festival-Video-Click', { button: 'Greatest-Festival-Video' });
      setYoutubeVideoId(videoId)
      openPopup()
    };

    const handleExploreMore = () => {
      Mixpanel.track('Greatest-Festival-Explore-More-btn-click', { button: 'Explore More' });
      history.push("/festival")
    };

    useEffect(() => {
      axios
        .get(`https://sij-dev-default-rtdb.firebaseio.com/api/data.json`)
        .then(response => {
          console.log(response.data.festivalHighlights)
          createVideoCards(response.data.festivalHighlights);
        })
        .catch(error => {
          console.log(error);
          setIsError(true);
        })
    }, [])

    async function createVideoCards(festivalHighlights) {
      let newVideoCards = [];
      for (const video of festivalHighlights) {
        const videoId = 1;

        const title = video.title;
        const subTitle = video.subTitle;
        const image = video.image;
        const ytVideoId = video.videoUrl;

        newVideoCards.push({
          videoId,
          title,
          subTitle,
          image,
          ytVideoId
        });
      };
      setVideoCards(newVideoCards);
      setIsLoading(false);
    }

    if(isError) {
      return <Alert severity="error" className='loading'>No Results found!</Alert>
    }
    return (
        
        <div className='festival'>
          <h1>WAR AGAINST WAR : ROSARY WARFARE</h1>
          <div className='line'></div>
          <br></br>
          <h2>If souls are saved, the war will end. This message that mother Mary gave when she appeared in Fatima, Portugal on 13th July 1917 is in the historical records of the Catholic Church. Br Tom Zachariah the servant of God and the Apostle of souls, received this message and formed this Rosary war which is a war against war.</h2>
            { isLoading ? <CircularProgress className='loading' color='secondary' /> : null }
            <div className="festival__events">
                {
                  videoCards.map(item => {
                    return (
                        <FestivalHighlightItem onClick={() => handleVideoClick(item.ytVideoId)}
                            key={item.videoId}
                            title={item.title}
                            subTitle={item.subTitle}
                            image={item.image}
                            ytVideoId={item.ytVideoId}
                        />
                    )
                  })
                }
            </div>
            <VideoPopup videoId={youtubeVideoId} isOpen={popupOpen} onClose={closePopup} />
            <button className="read-more" onClick={handleExploreMore}>Explore More</button>
        </div>
    )
}

export default FestivalHighlights;