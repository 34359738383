import React from 'react';
import '../App.css';
import { Button } from './Button';
import './HeroSection.css';
import { Link } from 'react-router-dom';
import  Mixpanel from 'mixpanel-browser';
import { useEffect } from 'react';

const getVideoSrc = width => {
  if (width >= 1080) return '/videos/promo.mp4';
  if (width <= 720) return '/videos/promo_mob.mov';
  return '/videos/promo.mp4';
};

const getPosterSrc = width => {
  if (width >= 1080) return '/images/home_l.png';
  if (width <= 720) return '/images/mobileHero.png';
  return '/images/home_l.png';
};

function HeroSection() {

  useEffect(() => {
    Mixpanel.init('312ba53aa81ecfff896426fb2d09e86a');
    Mixpanel.track('HomePage');
    
    try {
      // Get the device ID
     const deviceId = Mixpanel.get_distinct_id();

      // Set the device ID as a user property
      Mixpanel.identify();
      Mixpanel.people.set({
          '$name': 'Guest-User',
          '$device_id': deviceId,
      });
    } catch (error) {
      console.error('Error tracking Mixpanel user:', error);
    }
  }, []);

  const src = getVideoSrc(window.innerWidth);
  const poster_src = getPosterSrc(window.innerWidth)
  return (
    <div className='hero-container'>
      <video className = 'hero-container-video' src={src} poster= {poster_src} autoPlay loop muted />
      <div className="hero-title-text">Spirit In Jesus</div>
      <div className="multi-color-div">
          <span className="hero-subtitle-text">"I saw glowing, glittering, large white letters coming down from heaven. 
          I was filled with wonder as I gazed upon the letters. I read it: SPIRIT IN JESUS"</span>
      </div>
      <div className="author">- Br. Tom Zachariah</div>
      <Link to="/aboutus">
        <button className="read-more-button">Read More </button>
      </Link>
      <div className='hero-btns'>
        <Button
          className='btns'
          buttonStyle='btn--outline'
          buttonSize='btn--large'
          onClick={() => window.open("https://www.youtube.com/c/SpiritinJesus", '_blank')}  
        >
          YOUTUBE <i className='far fa-play-circle' />
        </Button>
      </div>
    </div>
  );
}

export default HeroSection;
