import React, {useEffect, useState} from 'react';
import VideoCard from '../../VideoCard/VideoCard';
import './Magazines.css';
import axios from 'axios';
import {DateTime} from 'luxon';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import MagazineCard from './MagazineCard'
import '../../../App.css';


const Magazines = () => {

    const [videoCards, setVideoCards] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);

    useEffect(() => {
      axios
        .get(`https://sij-dev-default-rtdb.firebaseio.com/api/data.json`)
        .then(response => {
          console.log(response.data.magazines)
          createVideoCards(response.data.magazines);
        })
        .catch(error => {
          console.log(error);
          setIsError(true);
        })
    }, [])

    async function createVideoCards(magazines) {
      let newVideoCards = [];
      for (const video of magazines) {
        const videoId = 1;

        const month = video.month;
        const image = video.thumbnail;
        const year = video.year;
        const fileUrl = video.fileUrl;
        const date = "";

        newVideoCards.push({
          videoId,
          image,
          month,
          year,
          date,
          fileUrl
        });
      };
      setVideoCards(newVideoCards);
      setIsLoading(false);
    }

    if(isError) {
      return <Alert severity="error" className='loading'>No Results found!</Alert>
    }
    return (
        
        <div className='magazines'>
          <h1>PUBLICATIONS</h1>
          <div className='line'></div>

            { isLoading ? <CircularProgress className='loading' color='secondary' /> : null }
            <div className="magazines__events">
                {
                  videoCards.map(item => {
                    return (
                        <MagazineCard key={item.videoId}
                            month={item.month}
                            image={item.image}
                            year={item.year}
                            date={item.date}
                            fileUrl={item.fileUrl}
                        />
                    )
                  })
                }
            </div>
        </div>
    )
}

export default Magazines;