export const items = [
    {
        status: "inactive",
        imgSrc: "https://firebasestorage.googleapis.com/v0/b/sij-dev.appspot.com/o/userAvatar%2Favatar.jpeg?alt=media&token=e6fca006-9796-41fa-b9d3-35fcd830d48a",
        name: "Justin",
        position: "Glory To God",
        quote: "My name is Justin. Myself and wife were both born through cesarean, and hence we had desired that our child should be born through a normal delivery. But the doctor said we would require operation. On the previous day of her admission in hospital I went to the church and prayed, ‘Lord, I pray in union with Spirit in Jesus anointments that my child may be born through a normal delivery’. I experienced great anointment during prayer. Even though the doctor said that operation was required in the last minute a normal delivery happened. Praise you Jesus, thank you Jesus."
    },
    {
        status: "inactive",
        imgSrc: "https://firebasestorage.googleapis.com/v0/b/sij-dev.appspot.com/o/userAvatar%2Ftestimony_bible.jpeg?alt=media&token=8244e43e-9098-49b5-bad8-a15efc446f2f",
        name: "Jaicy Scaria, Israel",
        position: "God Heals", 
        quote: "My name is Jaicy Scaria. I'm in Israel. I have been suffering from backache since last one year. I used to hear Tom Brother's preaching and pray along with it also when Brother asked to place our hands on the diseased part of our body and pray, I placed my hand and prayed. Due to that I'm healed from my backache completely. I thank Br Tom, Sr Catherine, and all the team members who prayed for me and all sick people. Praise the Lord!"
    },
    {
        status: "inactive",
        imgSrc: "https://firebasestorage.googleapis.com/v0/b/sij-dev.appspot.com/o/userAvatar%2Ftestimony_hand.jpeg?alt=media&token=aebb7393-dfc7-47a0-b635-56e8b59fb584",
        name: "Aleena Mary John",
        position: "Glory to the Lord",
        quote: "My name is Aleena Mary John, from Ernakulam. I'm an average student. This year I was in +2 and during the time of exam I had tension. I used to say the Wisdom Rosary, also I used to listen to the audio message sent by Br.Tom while studying and on the days of exams. Today when my results are out, I have passed with full marks in all subjects.. I thank Br Tom and Sr Catherine who prayed for me."
    },
    {
        status: "active",
        imgSrc: "https://firebasestorage.googleapis.com/v0/b/sij-dev.appspot.com/o/userAvatar%2Ftestimony_rosary.png?alt=media&token=6e8c1d88-8534-4f27-880a-8f31262789bb",
        name: "Lenis Antony, Ukraine",
        position: "Praise the Lord, Hallelujah! ",
        quote: "I am a first year medical student in Ukraine. I was also there when the war broke out. There was a fear of its terror everywhere. Tom brother and Catharine Sister Prayed for me and when I called Tom Brother He prayed for all the people in the Ukraine and especially for that country. When I heard that prayer, I felt like I was wrapped in a great blanket of salvation and nothing could happen to me. Through the prayer of Tom brother and Catherine sister and Mother Mary's protection, we were able to safely catch the last train to Hungary that day."
    },
    {
        status: "inactive",
        imgSrc: "https://firebasestorage.googleapis.com/v0/b/sij-dev.appspot.com/o/userAvatar%2Ftestimony_candle.jpeg?alt=media&token=8476d103-b417-428d-b676-bdaf20e3ab9a",
        name: "Jobin Thomas",
        position: "For nothing is impossible for God",
        quote: "My name is Jobin Thomas, Malayattor. My son Johan Jobin (4 years) had edema in his eyes and stomach few days back. When we consulted a doctor, his disease was diagnosed as Nephrotic Syndrome. I called Br Tom and request for prayers. From the next day onwards the swelling in his eyes and stomach subsided. Yesterday when we showed him to the doctor and took the test, the amount of protein in the urine was found to be very low. I thank the Father, Son and the Holy Spirit for the mighty healing God gave to my son. I thank the Holy Mother. I thank Br Tom and Sr Catherine who prayed for the child. Praise the Lord. Ave Maria. "
    }
];

export default items;