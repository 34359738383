import React, { useEffect, useState } from 'react';
import axios from 'axios';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import { useHistory } from 'react-router-dom';
import Mixpanel from 'mixpanel-browser';
import { client } from './../../sanity'; // Import the Sanity client and any necessary functions
import UpcomingEventItem from './UpcomingEventItem';
import { getEvents } from './../../sanity'; 
import { FormatBoldRounded } from '@material-ui/icons';
import imageUrlBuilder from '@sanity/image-url'

const builder = imageUrlBuilder(client)

function urlFor(source) {
  return builder.image(source)
}

const UpcomingEventsUpdated = () => {
  const [videoCards, setVideoCards] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const history = useHistory();

  const handleClick = () => {
    Mixpanel.track('Festival Button Clicked', { button: 'See-More' });
    history.push('/festival');
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const upcomingEvents = await getEvents(); // Fetch data from Sanity
        console.log(upcomingEvents);
        createVideoCards(upcomingEvents);
      } catch (error) {
        console.error(error);
        setIsError(true);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  async function createVideoCards(upcomingEvents) {
    let newVideoCards = [];
    for (const event of upcomingEvents) {
      const videoId = 1;
      const title = event.name;
      const image = urlFor(event.image);
      const location = event.description[0].children[0].text;
      const date = event.date;
      const mapLocation = event.mapLocation;
      const liveUrl = event.liveUrl ?? "";
      const liveImageUrl = urlFor(event.liveImage);

      newVideoCards.push({
        videoId,
        image,
        title,
        location,
        date,
        mapLocation,
        liveUrl,
        liveImageUrl,
      });
    }
    setVideoCards(newVideoCards);
  }

  if (isError) {
    return <Alert severity="error">No Results found!</Alert>;
  }

  return (
    <div className="upcomingevents">
      <h1>UPCOMING EVENTS</h1>
      <div className="line"></div>
      {isLoading ? <CircularProgress className="loading" color="secondary" /> : null}
      <div className="upcomingevents__events">
        {videoCards.map((item) => (
          <a
            key={item.videoId}
            color="inherit"
            href={item.liveUrl.length > 0 ? item.liveUrl : null}
            target="_blank"
            style={{ textDecoration: 'none', color: 'inherit' }}
          >
            <UpcomingEventItem
              title={item.title}
              image={item.liveUrl.length > 0 ? item.liveImageUrl : item.image}
              location={item.location}
              date={item.date}
              mapLocation={item.mapLocation}
            />
          </a>
        ))}
      </div>
    </div>
  );
};

export default UpcomingEventsUpdated;
