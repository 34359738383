import React, {Fragment, useEffect } from 'react';
import Navbar from './components/Navbar';
import './App.css';
import Home from './components/pages/Home';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Services from './components/pages/Services';
import Products from './components/pages/Products';

import YoutubeVideos from './components/pages/media/YoutubeVideos';
import UpcomingEvents from './components/pages/upcomingEvents/UpcomingEvents';
import YoutubeEmbed from './components/pages/media/YoutubeEmbed';
import Cards from './components/Cards';
import Magazines from './components/pages/magazines/Magazines';
import PrayerRequest from './components/pages/prayerRequest/PrayerRequest';
import Footer from './components/Footer';
import Testimonials from './components/pages/testimonials/Testimonials';
import ScrollToTop from './components/ScrollToTop';
import AboutUs from './components/aboutus/AboutUs';
import FestivalHome from './components/pages/festival/FestivalHome';
import Mixpanel from 'mixpanel-browser';
import DynamicBlog from './components/dynamicblog/DynamicBlog';
import UpcomingEventsUpdated from './components/pages/upcomingEvents/UpcomingEventsUpdated';

function App() {

  useEffect(() => {
    Mixpanel.init('312ba53aa81ecfff896426fb2d09e86a');

    const script = document.createElement("script");
    script.src = "https://www.googletagmanager.com/gtag/js?id=AW-10896196606";
    script.async = true;
    document.body.appendChild(script);

    const scriptConfig = document.createElement("script");
    scriptConfig.innerHTML = `
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', 'AW-10896196606');
  `;
  document.head.appendChild(scriptConfig);
  
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <>
      <Router>
        <Fragment>
          <ScrollToTop />
          <Navbar />
          <Switch>
            <Route path='/' exact component={Home} />
            <Route path='/services' component={Services} />
            <Route path='/products' component={Products} />
            <Route path='/youtubevideos' component={YoutubeVideos} />
            <Route path='/upcomingevents' component={UpcomingEventsUpdated} />
            <Route path='/media' component={Cards} />
            <Route path='/magazine' component={Magazines} />
            <Route path='/testimonials' component={Testimonials} />
            <Route path='/prayerrequest' component={PrayerRequest} />
            <Route path='/aboutus' component={AboutUs} />
            <Route path='/festival' component={FestivalHome} />
            <Route path='/article' component={DynamicBlog} />
          </Switch>
          <Footer />
        </Fragment>
      </Router>
    </>
  );
}

export default App;
