import React from 'react';
import './TableOfContents.css';

const TableOfContents = ({ className }) => {
  return (
    <div className={className}>
    <div className="toc-container">
      <h2 className="toc-title">Table of Contents</h2>
      <ul className="toc-list">
        <li><a href="#nameFromHeaven" color='blue'>1. A Name From Heaven</a></li>
        <li><a href="#iSawJesus">2. I Saw Jesus</a></li>
        <li><a href="#whatIsSpiritInJesus">3. What Is Spirit In Jesus</a></li>
        <li><a href="#spiritInJesusForLastDays">4. Spirit In Jesus For Last Days</a></li>
        <li><a href="#lastAnointing">5. The Last Anointing</a></li>
        <li><a href="#howToReceiveAnointing">6. How To Receive Anointing</a></li>
      </ul>
    </div>
    </div>
  );
}

export default TableOfContents;
