// sanity.js

import { createClient } from '@sanity/client';

export const client = createClient({
  projectId: 'e0zqtgrg',
  dataset: 'production',
  useCdn: true,
  apiVersion: '2023-03-25',
  // token: process.env.SANITY_SECRET_TOKEN // Only if you want to update content with the client
});

export async function getEvents() {
  const posts = await client.fetch('*[_type == "event"]');
  return posts;
}
