import React, {useEffect} from 'react';
import './FestivalHighlights.css';
import YouTube from 'react-youtube';
import CardItem from '../../CardItem';
import MyGallery from './MyGallery';
import Mixpanel from 'mixpanel-browser';

function FestivalHighlights() {

  useEffect(() => {
    Mixpanel.init('312ba53aa81ecfff896426fb2d09e86a');
    Mixpanel.track('Greatest-Festival-PageView');

    try {
      // Get the device ID
     const deviceId = Mixpanel.get_distinct_id();

      // Set the device ID as a user property
      Mixpanel.identify();
      Mixpanel.people.set({
          '$name': 'Guest-User',
          '$device_id': deviceId,
      });
    } catch (error) {
      console.error('Error tracking Mixpanel user:', error);
    }
  }, []);

  const opts_gen1 = {
    height: '100%',
      width: '100%',
    playerVars: {
      autoplay: 1,
      start: 0,
      mute: 1
    },
  };
  const opts_gen2 = {
    height: '100%',
    width: '100%',
    playerVars: {
      autoplay: 0,
      start: 0,
      mute: 1 
    },
  };

  return (
    <div className='festival-banner'>
      <div className='festival-home'>
      </div>
      <br/>
      <h1>LED BY</h1>
      <div className='line'></div>
        <div className='cards__wrapper_festival'>
          <ul className='cards__items'>
            <CardItem
              src='images/brother.png'
              text='Brother Tom Zachariah'
            />
            <CardItem
            src='images/gallery_2.png'
            text='Sister Catherine Mariam'
            />
          </ul>
        </div>
      {/* <div className='fatima-mission'>
      </div> */}
      <br></br>
      {newCreation()}
      <h1>2022 GREAT FESTIVAL</h1>
      <div className='line'></div>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            <MyGallery/>
          </ul>
        </div>
      </div>
    </div>
  );


  function newCreation() {
    return <article className='great-festival'>
        <br/>
        <div className='youtube-video-container'>
        <YouTube videoId="OlupZuKCctk" opts={opts_gen1}/>
        </div>
        <br/>
        <header>
                <h2>The Fatima Mission</h2>
        </header>

        <div className="image-container">
                <img src="https://firebasestorage.googleapis.com/v0/b/sij-dev.appspot.com/o/festival%2Ffatima-1.png?alt=media&token=8b2113a1-1142-4bf4-9df5-7da624bfe439" alt="blog post" />
        </div>
        <blockquote className="multi-color-quote">
                <span className="red">Holy Mother told the children :</span>
                <span className="blue"> “You have seen the hell where the souls of poor sinners go. To save them, God the 
                Father wants to establish in the world the devotion to my Immaculate Heart ". Our Lady in Fatima instructed them 
                to pray the Rosary every day for the salvation of souls in hell. At that time, the world was suffering from the 
                calamities of the first World War. She said, "If the souls are saved, war will end, and  peace will come on earth</span>
        </blockquote>
        <p>The Fatima mission, which holds significant importance, was entrusted to the Church by Holy Virgin Mary during her apparitions in Fatima, Portugal in 1917. "
In the apparition of July 13, 1917, the Holy Mother showed  three children Hell and the torments of the souls in Hell. When the children cried out seeing that, Holy Mother said, 
“You have seen the hell where the souls of poor sinners go. To save them, God the Father wants to establish in the world the devotion to my Immaculate Heart ". Our Lady in Fatima 
instructed them to pray the Rosary every day for the salvation of souls in hell. At that time, the world was suffering from the calamities of the first World War. She said, 
"If the souls are saved, war will end, and  peace will come on earth.
The authorities couldn't accept the revelations from heaven and refused to fulfill the instructions given by Holy Mother, thus a second world war broke out.
It is noteworthy that, currently, humanity is facing endless catastrophic disasters and calamities after the 100th anniversary of Fatima apparitions in 2017.
        </p>
        <br></br>
        <header>
                <h2>Gospel for the dead</h2>
        </header>
        <div className="image-container">
                <img src="https://firebasestorage.googleapis.com/v0/b/sij-dev.appspot.com/o/festival%2Ffatime-2.png?alt=media&token=ed5c4715-dc82-4e07-ac5d-ea7a87a977da" alt="blog post" />
        </div>
        <blockquote className="multi-color-quote">
                <span className="red">In the year 1997, The LORD commanded His servant to preach the Gospel to the dead.</span>
                <span className="blue">  He obeyed the LORD and started 
          preaching to the dead fulfilling the word of God in John 5:25, "the dead will hear the voice of 
          the Son of God."</span>
        </blockquote>
        <p>Brother Tom Zachariah, the servant of the LORD has received numerous heavenly revelations 
          about the hidden world of lost souls. The Lord taught him truths about the lost souls, what's 
          their condition , how satan use them to spread his world of sin, and how they influence humankind 
          and create problems and calamities in the world. The Lord taught him the way to deliver these 
          souls from Satan's bondage. The promise given in Philippians 2 /10-11 was fulfilled that, " at the name 
          of Jesus every knee should bend in heaven and  on earth and under the earth ".  In the year 1997, 
          The LORD commanded His servant to preach the Gospel to the dead. He obeyed the LORD and started 
          preaching to the dead fulfilling the word of God in John 5:25, "the dead will hear the voice of 
          the Son of God."</p>
          <br></br>
          <header>
                <h2>Jesus Descended into hell</h2>
        </header>

        <div className="image-container">
                <img src="https://firebasestorage.googleapis.com/v0/b/sij-dev.appspot.com/o/festival%2Ffatima-3.png?alt=media&token=5c4f3f0b-d0cb-4edf-b6fe-463537885fc0" alt="blog post" />
        </div>
          <blockquote className="multi-color-quote">
                <span className="red">Jesus through his death on the cross destroyed  the dominion of satan in hell. 
            Jesus descended into hell, preached Gospel to the spirits imprisoned there,  
            opened the doors of salvation to all, and ascended into heaven.</span>
                <span className="blue"> Now,Jesus holds the keys of death and hell (Rev. 1/18).</span>
        </blockquote>

          <p>Jesus through his death on the cross destroyed the dominion of satan in hell. 
            Jesus descended into hell, preached Gospel to the spirits imprisoned there,  
            opened the doors of salvation to all, and ascended into heaven. the Apostle's Creed 
            clearly tells that 'Jesus descended into hell'. The Catechism of the Catholic church (CCC) 
            is written on the basis of the Apostle's Creed. Now,Jesus holds the keys of death and hell (Rev. 1/18). 
            Hence, when those living will pray for the salvation of souls Jesus will come to save them. 
            Eternal damnation is only after the final judgment. There is salvation for those who are in hell. </p>
            <br></br>
        <header>
                <h2>Spirit in Jesus With Fatima Mission</h2>
        </header>

        <div className="image-container">
                <img src="https://firebasestorage.googleapis.com/v0/b/sij-dev.appspot.com/o/festival%2Ffatima-4.png?alt=media&token=72d71783-3641-49d0-b08d-e30d95e3110e" alt="blog post" />
        </div>
        <blockquote className="multi-color-quote">
                <span className="blue"> When nothing was done for 
          the salvation of souls, God in his mercy sent the Spirit in Jesus anointing to the world  
          71 years after the Fatima apparitions.</span>
                <span className="red"> In 1988 March God anointed His servant Brother 
          Tom Zachariah with three heavenly missions - Salvation of all souls, Glorification 
          of Holy Virgin Mary and the sanctification of the church.</span>
        </blockquote>
        
        <p>The souls who are under the slavery of satan are behind all the calamities occuring in this world. 
          Appointed by Heavenly Father, Holy Virgin Mary appeared in Fatima for six consecutive months to 
          reveal this truth to the world. Yet, when nothing was done for the salvation of souls, God 
          in his mercy sent the Spirit in Jesus anointing to the world 71 years after the Fatima apparitions. 
          In 1988 March God anointed His servant Brother Tom Zachariah with three heavenly missions - 
          Salvation of all souls, Glorification of Holy Virgin Mary and the sanctification of the Church. 
          Holy Spirit taught and guided His servants in all these years. The children of Spirit in Jesus are those who found their strength in the Holy Rosary prayer. </p>
          <br></br>
          <header>
                <h2>Holy Mary is a mystery hidden to the world</h2>
        </header>

        <div className="image-container">
                <img src="https://firebasestorage.googleapis.com/v0/b/sij-dev.appspot.com/o/festival%2Ffatima-5.png?alt=media&token=7edd2ef2-f6a9-435f-bd20-55a5d53549d4" alt="blog post" />
        </div>
          <blockquote className="multi-color-quote">
                <span className="blue"> Holy Mary has 
          been appointed to prepare this world and its inhabitants for the second coming of Her Son Jesus.</span>
                <span className="red">According to Genesis 3:15 it is through Holy Mary- the promised woman- that the head 
          of the serpent (the devil) will be crushed and peace will be established in the world.</span>
        </blockquote>

        <p>For centuries Holy Mary remained a hidden mystery so that the glory of Jesus, the only 
          savior would not be diminished in any way." (Rev. 12/14). But now Holy Mary has been appointed 
          to prepare this world and its inhabitants for the second coming of Her Son Jesus. 
          According to Genesis 3:15, it is through Holy Mary- the promised woman- that the head 
          of the serpent (the devil) will be crushed and peace will be established in the world.</p>
          <br></br>
          <a href="/article">The reoccurrence of Fatima Sun miracle in Maryland, Suryanelli, India</a>
        <div className='youtube-video-container'>
            <YouTube videoId="1WGO-pnDUYs" opts={opts_gen2}/>
        </div>
    </article>;
}
}

export default FestivalHighlights;
