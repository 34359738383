// SubscribeBanner.js
import React from 'react';
import { Link } from 'react-router-dom';
import './SubscribeBanner.css'; // Import CSS file

const SubscribeBanner = () => {
  // Replace 'imageURL' with the actual URL of your background image
  const imageURL = 'https://firebasestorage.googleapis.com/v0/b/spiritinjesus-21cb4.appspot.com/o/subscribe%2Fsubscribe_image.jpeg?alt=media&token=ecbb74de-4fd4-44ca-b602-cad80dcf4391';

  return (
    <div className="white-container"> {/* Add a container div */}
      <div className="subscribe-banner">
        <div className="subscribe-background" style={{backgroundImage: `url(${imageURL})`}}></div>
        <div className="subscribe-content">
          <div className="content-box">
            <h2>Experience freedom, & healings</h2>
            <p>Come & find rest for your souls. Sign up for weekly gospel teachings and be blessed. We will give you the right amount of love!</p>
              <button onClick={() => window.open("https://spirit-in-jesus.ck.page/7ddbfc7520", '_blank')}>Subscribe</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscribeBanner;
