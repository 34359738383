import React from 'react';
import './ArticleItem.css';
import Mixpanel from 'mixpanel-browser';


function handleGoogleMapClick(event) {
  Mixpanel.track('Google map link click', { button: 'Google-Map' });
}

const ArticleItem = ({ onClick, title, subTitle, image, ytVideoId }) => {
  return (
    <div onClick={onClick} className='blog_item'>
      <div className='video-container'>
      <img className='blog_item__image' src={image} alt='' />
      </div>
        <div className="blog_item__text">
          <div className='blog_item_title'>
            <h4>{title}</h4>
            <p> {subTitle}</p>
          </div>
        </div>
    </div>
  )
}

export default ArticleItem;
