import React, {useEffect, useState} from 'react';
import './Articles.css';
import axios from 'axios';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import '../../../App.css';
import { useHistory } from "react-router-dom";
import Mixpanel from 'mixpanel-browser';
import ArticleItem from './ArticleItem';




const Articles = () => {

    const [videoCards, setVideoCards] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);

    const history = useHistory();

    const handleVideoClick = (videoId) => {
      Mixpanel.track('Greatest-Festival-Video-Click', { button: 'Greatest-Festival-Video' });
    };

    const handleExploreMore = (navigation) => {
      Mixpanel.track('Greatest-Festival-Explore-More-btn-click', { button: 'Explore More' });
      history.push(navigation)
    };

    useEffect(() => {
      axios
        .get(`https://sij-dev-default-rtdb.firebaseio.com/api/data.json`)
        .then(response => {
          console.log(response.data.articles)
          createVideoCards(response.data.articles);
        })
        .catch(error => {
          console.log(error);
          setIsError(true);
        })
    }, [])

    async function createVideoCards(articles) {
      let newVideoCards = [];
      for (const video of articles) {
        const videoId = 1;

        const title = video.title;
        const subTitle = video.subTitle;
        const image = video.image;
        const navigation = video.navigation;

        newVideoCards.push({
          videoId,
          title,
          subTitle,
          image,
          navigation
        });
      };
      setVideoCards(newVideoCards);
      setIsLoading(false);
    }

    if(isLoading) {
      return <Alert severity="error" className='loading'>Loading...</Alert>
    }
    return (
        
        <div className='blog'>
          <h1>READ OUR ARTICLES</h1>
          <div className='line'></div>
            { isLoading ? <CircularProgress className='loading' color='secondary' /> : null }
            <div className="blog__events">
                {
                  videoCards.map(item => {
                    return (
                        <div>
                        <ArticleItem onClick={() => handleVideoClick(item.navigation)}
                            key={item.videoId}
                            title={item.title}
                            subTitle={item.subTitle}
                            image={item.image}
                            navigation={item.navigation}
                        />
                       <button className="read-article-button" onClick={ () => handleExploreMore(item.navigation)}>Read full article</button>
                        </div>
                        
                    )
                  })
                }
            </div>
        </div>
    )
}

export default Articles;