import React, { useState, useEffect } from "react";
import Mixpanel from 'mixpanel-browser';
import './Article.css'

function Article({ text }) {

    useEffect(() => {
        Mixpanel.init('312ba53aa81ecfff896426fb2d09e86a');
        Mixpanel.track('About-SIJ-ReadMore-page');
      }, []);

    const [showFullText, setShowFullText] = useState(false);

    const handleReadMore = () => {
        Mixpanel.track('AboutUs-SIJ-ReadMore', { button: 'ReadMore' });
        setShowFullText(true);
    };

    return (
        <div>
            {showFullText ? (
                <div>
                    <br></br>
                    <p>
                    As Jesus disappeared, Mother Mary suddenly appeared in her blue robe. 
                    I saw her raising both her hands and blessing me. She turned to her right side and then disappeared.</p>
                    <br></br>
                    <p>As she disappeared, then there came two angels. They were very tall and had a robust appearance, 
                        wearing yellow robes. Both held golden trumpets and were blowing them. As they were blowing the trumpets, 
                        I could see their eyes shining and their beautiful puffed cheeks moving. 
                        They stood there for some time, then they disappeared.</p>
                    {heavenlyRevaltions()}
                    {confirmationOfRevelations()}
                    {newCreation()}
                </div>
            ) : (
                <div className="container">
                    <br></br>
                    <p>{text}...</p>
                    <br></br>
                    <button className="read-more" onClick={handleReadMore}>Read More</button>
                </div>
            )}
        </div>
    );

    function heavenlyRevaltions() {
        return <div id="heavenlyRevelations">
            <br/>
            <header>
                <h2>HEAVENLY REVELATIONS</h2>
                <h6>Year, 1988</h6>
            </header>
            <div className="image-container">
                <img src="https://firebasestorage.googleapis.com/v0/b/sij-dev.appspot.com/o/about_us%2Fvisions.png?alt=media&token=75843ce1-3d8b-49e7-adf5-77c2155eded2" alt="blog post" />
            </div>
            <p>Another scenario unfolded. I was seeing beautiful flowers falling down from up above. Within seconds 
                it piled up to form a mountain. I was looking at the top of that heap of flowers. 
                Slowly my eyes went up. I saw a heavenly place. I could see thousands of Israelites in their jute robes, 
                all praising and dancing with great joy. Suddenly I heard a voice, “Now there is great joy in heaven’’. 
                Today I know what it meant. The saints I saw in heaven were the prophets, apostles and the faithful servants 
                of God. While I was watching them my eyes further went up. I saw a heavenly place which was full of dazzling 
                light. It was as if I was looking at the sun. What an awesome glare and glory! I was not able to look at the 
                light. I saw millions of angels below that light, glistening like diamonds. I cannot explain their beauty 
                with my human words. They were all worshiping and praising the Almighty God..</p>
            <br></br>
            <p>It was like watching a movie in a theatre. What I saw there were not visions but revelations. 
                While I was in this world, God was unveiling many mysteries of His realm to me. These were all revelations that 
                lasted for a considerable time. In vision you see different things within a particular time frame, 
                but revelation is like watching a film.
            </p>
            {/* <blockquote>
        <p>This is a special quote with a larger font size.</p>
    </blockquote> */}
            <blockquote className="multi-color-quote">
                <span className="blue">"I saw a heavenly place. I could see thousands of Israelites in their jute robes, all praising and dancing with great joy. Suddenly I heard a voice, “Now there is great joy in heaven"</span>
                <span className="red">I saw a heavenly place which was full of light. It was as if I was looking at the sun. What an awesome glare and glory! I was not able to look at the light. I saw millions of angels below that light, glistening like diamonds.</span>
            </blockquote>
            <p>Then things took another turn. The Spirit of God took me to various places. 
                That means my spirit (soul) visited various places, assisted by the Spirit of God. 
                After seeing the third Heaven the Holy Spirit showed me the earth. I found myself to be 
                somewhere up above the earth, looking down at millions of people, crowds, and multitudes, 
                all gazing upward, waiting for God. This revelation repeated three times and I asked the Spirit, 
                ‘Who are they? Why are they looking up?’ Suddenly the Spirit of God took me very close to the 
                earth and to the crowd. In that multitude He showed me a man whom I knew very well. 
                A young man with a beard, looking extremely depressed. I had heard about him long ago that he was 
                then a gang leader, causing troubles in his village. I saw his face up close, and three months 
                after this vision, I met him at a wedding, well dressed, with a clean-shaved face. 
                I told him that I had seen him in a revelation three months ago in a pitiful condition. 
                He told me that he didn't know what had happened to him. Three months ago his personality took an abrupt 
                turn and stopped all his antisocial activities. He is now happily looking after his family. 
                Yes, that is what God is going to do for everyone who is longing for God. The multitudes I saw - God loves them. 
                He has a plan for everyone; a plan for their wellbeing.</p>
            <br></br>
            <blockquote className="multi-color-quote">
                <span className="red">The Spirit of God took me to Israel. </span>
                <span className="blue">I saw the hills, the plain lands and the people.</span>
            </blockquote>
            <p>On that night the Spirit of God took me to Israel. I saw the hills, the plain lands and the people. 
                I saw the Israeli women holding jars, collecting water from the well and walking through the wheat 
                fields keeping the jars on their side. Seeing the place I understood that this was the land of Israel. 
                The movie of heaven moved on. The Holy Spirit was next taking me to Rome. While passing through a 
                huge building I heard a voice, ‘this is St.Peter’s Basilica’. I had never seen an image of that 
                basilica before. I was like flying through the air, and my attention was drawn towards the huge 
                pillars of the basilica, although I wasn't sure why. Soon after, the Holy Spirit took me to the Colosseum, 
                a place I had only read about in history books. He said, ‘this is the Colosseum’.</p>
            <br></br>
            <p>Up to this point, my two-hour-long experience in the chapel had been purely heavenly, 
                but it was not destined to be heavenly alone. At last it happened. A dirty, ugly, cruel 
                figure was standing before me. It was the devil standing just in front of me, staring with his 
                ferocious, beast-like gaze. I saw him like a cruel, wild beast.This devilish figure frightened 
                me so much that I bolted out of the chapel and ran back to my room. I was so shocked and frightened. 
                My roommate was in a deep sleep and was not aware of what had happened to me. I looked around, 
                horrified. I sat on my bed, I wanted to use the attached restroom but I was too terrified even to move. 
                I struggled to comprehend how it was possible for the devil to manifest in such a divine and holy ambiance. 
                Despite my joyful experiences of encountering Jesus, Mother Mary, angels, and the splendor of heaven, 
                which brought me immense joy, the appearance of the devil left me shocked and scared. 
                All the joy I had previously experienced vanished in the wind.</p>
            <br></br>
            <blockquote className="multi-color-quote">
                <span className="blue">I saw Mother Mary....When I finished three Hail Marys,</span>
                    <span className="red"> immediately I saw mighty power descending from heaven like a shower</span>
            </blockquote>
            <p>I suddenly sensed a movement in the atmosphere within my room and saw Mother Mary standing 
                up there and showing me a rosary. I saw her fingers moving through the beads of the rosary, 
                and I understood she was asking me to recite it. Then Mother Mary disappeared. Even Though 
                I had no rosary in my hand, I began to recite the prayer ‘Hail Mary’. After reciting three Hail Marys, 
                I immediately saw a mighty power descending from heaven like a shower. It fell upon me removing all my 
                fears and making me feel strong and courageous. I then took a bath and had a good night's sleep.
                Oh! It was a moment of thrill. After seeing the devil, I felt a sense of lifelessness, my 
                mind was shattered, and I was overwhelmed by fear.. Nonetheless, when Mother Mary 
                appeared and presented me with the rosary, and I recited three Hail Marys, I underwent 
                a remarkable transformation, feeling strong and empowered. This experience was a revelation to me.
                 In the face of all of your destroying storms, if you recite the Hail Mary, or if you say the rosary, that will save you from the pit of destruction.
            </p>
        </div>;
    }

    function confirmationOfRevelations() {
        return <div>
            <br/>
            <header>
                <h2>CONFIRMATION OF REVELATIONS</h2>
                <h6>On the very nexy day.</h6>
            </header>
            <div className="image-container">
                <img src="https://firebasestorage.googleapis.com/v0/b/sij-dev.appspot.com/o/about_us%2Fcross.png?alt=media&token=32459f27-5e27-4fda-aa59-cfd8cddad7e1" alt="blog post" />
            </div>
            <p>When I woke up the next morning, I felt like I had entered a different world. 
                I continued to experience divine revelations and whenever I closed my eyes, I could see only heavenly things. 
                During the day while listening to the word of God in class, closing my eyes, I was seeing marvelous things. 
                However, by midday, I began to question what was happening to me ‘I cannot believe all these things. 
                I was wondering what had happened to me. I recalled hearing about people who attended charismatic retreats 
                and lost their minds and became abnormal. I wondered if I too would become abnormal. I said, ‘no, 
                I won’t believe these things. Something bad had happened to me. I suspected that the preachers 
                had played some kind of trick on me. Countless questions began to race through my mind. During the afternoon session, 
                I lost all of the revelations I had experienced earlier. Every time I closed my eyes, I could only see darkness, 
                which left me feeling depressed by evening. As I reflected on the magnificent moments from the previous day, 
                such as my encounters with Jesus, Mother Mary, angels, and glimpses of heaven, I couldn't help but feel 
                disheartened and confused by the appearance of the devil. That destroyed everything.</p>
            <br></br>
            <p>At 9.30PM, after the prayer session I quickly made my way to the chapel and fell to my knees in prayer. 
                I asked, Lord Jesus, yesterday I was so happy when I saw you. But now all peace and joy has vanished 
                from my heart. Lord, I came here in search of you. I told Him that if I didn't receive a response from Him, 
                it would be the end of my life. I needed to know if what I saw was true, and why He would allow the 
                devil to terrify me in the midst of such beautiful experiences. Despite praying for half an hour, 
                I still received no answer. I could see only darkness before my eyes.
            </p>
            {/* <blockquote>
        <p>This is a special quote with a larger font size.</p>
    </blockquote> */}
            <blockquote className="multi-color-quote">
                <span className="blue">"All of a sudden, that cross which was far away from me turned to my side and came very near to me. Now I could see a big cross in front of my eyes. I saw Jesus nailed to the cross, shedding blood. I saw the crown of thorns on his head. "</span>
            </blockquote>
            <p> I said, Lord , I am not going to leave this chapel until I get an answer from you. 
                I decided not to leave the chapel and pray continuously till morning. As soon as I made this decision, 
                I saw a cross in the distance and Jesus was on the other side. I could see only the back side of the cross 
                and I couldn't see his face. I asked, Lord , are you angry with me? Are you unfriendly to me? 
                Forgive me Lord, I cannot believe anything. Immediately, the cross that was previously far away from 
                me turned to my side and moved very near to me. Now I could see a big cross in front of my eyes. 
                I saw Jesus nailed to the cross, shedding blood. I saw the crown of thorns on his head. 
                The thorns were very sharp and long. I saw the thorns only on the outward side of that crown. 
                In the inward side the thorns were pierced into his skull. I saw blood gushing out of his head and 
                his long hair soaked with the blood. I saw blood gushing out of his hands and feet, drip by drip. 
                I saw the wounds on his body. Truly I tell you, no one could watch that crucified body of Jesus for a long time. 
                As I observed the suffering he endured, my heart shattered, and I confessed, Jesus I believe you; 
                I believe that you died for me, to save me from my sins. I knelt down before him and said, Lord 
                I surrender myself before thee. In all these years I lived for me, but from now onwards I will be living for you. 
                I want to tell the whole world that Jesus is living, that He is the true God. That was a vow, a covenant that 
                I made with the Lord. I regained all the peace that I had lost, and I left the chapel for my room with great 
                joy and happiness.</p>
            <br></br>
            <blockquote className="multi-color-quote">
                <span className="blue">I want to tell the whole world that Jesus is living, that He is the true God.
              </span>
                    <span className="red">
                    That was a vow, a covenant that I made with the Lord</span>
            </blockquote>
        </div>;
    }

    function newCreation() {
        return <div>
            <br/>
            <header>
                <h2>A NEW CREATION</h2>
                <h6>I became a new being, with a new heart, with a new mind</h6>
            </header>
            <div className="image-container">
                <img src="https://firebasestorage.googleapis.com/v0/b/sij-dev.appspot.com/o/about_us%2Fnew_life.png?alt=media&token=43cc53f8-af14-40de-ac30-882826ae9df1" alt="blog post" />
            </div>
            <p>I evolved from the retreat center like a newborn chick breaking out of its eggshell, as a new creation. 
                I transformed into a new being with a new heart and mind . Everything changed in me. 
                It has been many years since I smiled and I even forgot how to smile. I was a shattered man and 
                felt my heart was broken into many pieces and no one could reunite it. The problems of life had 
                crushed me down so heavily, but now I am a new creation, with a peaceful and joyful heart. 
                I recollect an incident during my counseling session with a counselor, where he laid his hands 
                on my head and prayed over me. Instantly, I was overwhelmed by the power of the Holy Spirit, 
                which caused my entire body to shake. I felt like hot water flowing through my head, gradually 
                moving down towards my jaw. When that flow reached my jaw I felt a hand touching my lips. 
                Suddenly my heart leapt for joy and my lips opened with happiness, like a blooming flower.</p>
            <br></br>
            <p>For the first time in my life I was experiencing the touch and love of the Holy Spirit. 
                After that I was longing for him more and more. The last day of the retreat was awesome. 
                When the priest and some lay people laid their hands on our heads and prayed I felt as if I was in heaven. 
                I saw the awesome power and presence of the Holy Spirit. That room was filled with tongues of 
                fire and people were praising God in tongues. Those precious moments were unforgettable, and 
                I knew they would stay with me forever.
            </p>
            {/* <blockquote>
        <p>This is a special quote with a larger font size.</p>
    </blockquote> */}
            <blockquote className="multi-color-quote">
                <span className="blue">"I am a new creation, with a new, peaceful, joyful heart....Power of the Holy Spirit came upon me and it shook my whole body.
                    I felt like hot water flowing through my head"</span>
            </blockquote>
            <p>My return journey was to my elder sister’s house in Vythiri, Wayanad District. 
                While in the bus I had an inspiration to close my eyes. I couldn’t open my eyes until 
                the bus departed the city. The Holy Spirit, the helper as Jesus mentioned in the book of John 14:15, 
                started his work in my life. He began to teach me, train me, guide me and lead me. Reaching home at 
                Suryanelli in Idukki district as a new creation, I started a new life of prayer. 
                I wanted to be with the Lord every time. I didn't want any pleasures of this world; all 
                I wanted to do was to pray. . It was in such a prayer that I saw the glowing, glittering 
                letters coming down from heaven, which I read : “SPIRIT IN JESUS’’. Hallelujah!</p>
            <br></br>
            <blockquote className="multi-color-quote">
                <span className="red">The Holy Spirit the helper, as Jesus said in the book of John 14:15, started his work in my life.
                  </span>
                    <span className="blue">
                    He started to teach me, to train me, to guide me and to lead me</span>
            </blockquote>
        </div>;
    }


}

export default Article;
