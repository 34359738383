import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import './MagazineCard.css';
import { Button } from './../../Button';

const MagazineCard = ({ image, month, year, date,fileUrl }) => {
  return (
    <div className='magazine_card'>
      <img className='magazine_card__image' src={image} alt='' />
      <div className="magazine_card__info">
        <div className="magazine_card__text">
          <h4>{month} {year}</h4>
          <Button
            className='btns'
            buttonStyle='btn--primary'
            buttonSize='btn--small'
            onClick={() => window.open(fileUrl)}
          >
            Download
          </Button>
          <p>{date}</p>
        
        </div>
      </div>
    </div>
  )
}

export default MagazineCard;
