import React from 'react';
import Modal from 'react-modal';

const VideoPopup = ({ videoId, isOpen, onClose }) => {

    const getWidthForVideo = width => {
        if (width >= 1080) return '560';
        if (width <= 720) return '320';
        return '560';
      };

const video_width = getWidthForVideo(window.innerWidth)
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Video Popup"
      ariaHideApp={false}
      className="video-modal"
    >
      <div>
        <button className= "close-button" onClick={onClose}>Close</button>
        <iframe
          title="YouTube Video"
          width={video_width}
          height="315"
          src={`https://www.youtube.com/embed/${videoId}`}
          frameBorder="0"
          allow="autoplay; encrypted-media"
          allowFullScreen
        ></iframe>
      </div>
    </Modal>
  );
};

export default VideoPopup;
