import React from 'react';
import './Footer.css';
import { Button } from './Button';
import { Link, useHistory } from 'react-router-dom';

function Footer() {

  const history = useHistory();
  const home = () => {
    history.push("/prayerrequest");
  }

  return (
    <div className='footer-container'>
      <section className='footer-subscription'>
        <p className='footer-subscription-heading'>
          " The Good News is heard in Heaven on Earth and in Hades " John 5:28,29
        </p>
        <p className='footer-subscription-text'>
          We are here to pray for you
        </p>
        <div className='input-areas'>
          <form>
          <Link to="/prayerrequest">
           <button className="prayer-button">Request Prayer</button>
          </Link>
          </form>
        </div>
      </section>
      <div class='footer-links'>
        <div className='footer-link-wrapper'>
          <div class='footer-link-items'>
            <h2>International</h2>
            <Link to='/'>Marian center</Link>
            <Link to='/'>Manchester, U.K.</Link>
            <Link to='/'>Tel : 00447403267165</Link>
          </div>
          <div class='footer-link-items'>
            <h2>National</h2>
            <Link to='/'>Marian center</Link>
            <Link to='/'>Bunglow No. 1,</Link>
            <Link to='/'>Sai Deep Residency</Link>
            <Link to='/'>Evershine City</Link>
            <Link to='/'>Vasai East</Link>
            <Link to='/'>Pin- 401 209</Link>
            <Link to='/'>Tel : 9075849443</Link>
            <Link to='/'></Link>
          </div>
        </div>
        <div className='footer-link-wrapper'>
          <div class='footer-link-items'>
            <h2>Marian Koodaram</h2>
            <Link to='/'>Kachery</Link>
            <Link to='/'>Kuriachira P.O</Link>
            <Link to='/'>Thrissur -680 006.</Link>
            <Link to='/'>Ph: 7025474444</Link>
          </div>
          <div class='footer-link-items'>
            <h2>Social Media</h2>
            <a href='https://www.instagram.com/spiritinjesusministries/' target="_blank">Instagram</a>
            <a href='https://www.facebook.com/spiritinjesus/' target="_blank">Facebook</a>
            <a href='https://www.youtube.com/c/SpiritinJesus' target="_blank">Youtube</a>
            <a href='https://twitter.com/spiritinjesus' target="_blank">Twitter</a>
            <a href='https://open.spotify.com/show/6t4dhN8SuIGFCs6leCpeWi' target="_blank">Spotify</a>
            <a href='https://podcasts.google.com/feed/aHR0cHM6Ly9hbmNob3IuZm0vcy9kOWMxOWVlOC9wb2RjYXN0L3Jzcw?sa=X&ved=0CAMQ9sEGahcKEwjQgIuU45D9AhUAAAAAHQAAAAAQaw' target="_blank">Google Podcast</a>
          </div>
        </div>
      </div>
      <section class='social-media'>
        <div class='social-media-wrap'>
          <small class='website-rights'>Spirit In Jesus © 2023</small>
          <div class='social-icons'>
            <Link
              class='social-icon-link facebook'
              to={{ pathname: "https://www.facebook.com/spiritinjesus/" }}
              target='_blank'
              aria-label='Facebook'
            >
              <i class='fab fa-facebook-f' />
            </Link>
            <Link
              class='social-icon-link instagram'
              to={{ pathname: "https://www.instagram.com/spiritinjesusministries/" }}
              target='_blank'
              aria-label='Instagram'
            >
              <i class='fab fa-instagram' />
            </Link>
            <Link
              class='social-icon-link youtube'
              to={{ pathname: "https://www.youtube.com/c/SpiritinJesus" }}
              target='_blank'
              aria-label='Youtube'
            >
              <i class='fab fa-youtube' />
            </Link>
            <Link
              class='social-icon-link twitter'
              to={{ pathname: "https://twitter.com/spiritinjesus" }}
              target='_blank'
              aria-label='Twitter'
            >
              <i class='fab fa-twitter' />
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Footer;
