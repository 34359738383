import React, { useState, useEffect } from "react";
import './AboutUs.css';
import './Article.js'
import Article from "./Article.js";
import TableOfContents from "./TableOfContents";
import YouTube from 'react-youtube';
import Mixpanel from 'mixpanel-browser';


const AboutUs = () => {

    useEffect(() => {
        Mixpanel.init('312ba53aa81ecfff896426fb2d09e86a');
        Mixpanel.track('About-SIJ-PageView');
      }, []);
    

    const [showFullText, setShowFullText] = useState(false);

    const handleReadMore = () => {
        setShowFullText(true);
    };

    const startSeconds = 147; // start at 30 seconds
   
    const opts = {
      height: '100%',
      width: '100%',
      playerVars: {
        autoplay: 0,
        start: startSeconds,
      },
    };
    const opts_gen = {
        height: '80%',
        width: '100%',
        playerVars: {
          autoplay: 0,
          start: 0,
        },
      };

    return (
        <div className="blog-container">
            <header>
            <img src = "https://firebasestorage.googleapis.com/v0/b/sij-dev.appspot.com/o/about_us%2Fblog_title-image.gif?alt=media&token=a0f05ac2-af2d-48cd-ac2b-16ca0a9b5671" width= "auto" height= "300"/>          
            </header>
            <TableOfContents className="blog-toc-mob"/>
            <main>
                <section className="left-section">
                    {nameFromHeaven()}
                    {iSawJesus()}
                    {whatIsSpritiInJesus()}
                    {spiritInJesusForLastDays()}
                    {lastAnointing()}
                    {recieveAnointing()}
                </section>
                <aside className="right-section">
                    <TableOfContents className="blog-toc-desktop"/>
                    <div>
                        <h2>Author</h2>
                        <div className="image-container">
                            <img src="https://firebasestorage.googleapis.com/v0/b/sij-dev.appspot.com/o/about_us%2Fbrother_author.png?alt=media&token=db50715d-f38e-4e38-b58c-29bfda6cfaef" alt="author" />
                        </div>
                        <p className="right-section-subtext">Brother Tom Zachariah</p>
                    </div>
                    <div>
                        <h3>Popular Post</h3>
                        <div className="image-container">
                        <YouTube videoId="GWEb9Tu36Oo" opts={opts_gen}/>
                        </div>
                        <div className="image-container">
                        <YouTube videoId="iQhEvdLj8Y0" opts={opts_gen}/>
                        </div>
                        <div className="image-container">
                        <YouTube videoId="cMRugMEnvPs" opts={opts_gen}/>
                        </div>
                    </div>
                    {/* <div>
                        <h3>Follow Me</h3>
                        <p className="right-section-subtext">Some text...</p>
                    </div> */}
                </aside>
            </main>
        </div>
    );

    function nameFromHeaven() {
        return <article id="nameFromHeaven">
            <header>
                <h2>SPIRIT IN JESUS: A NAME GIVEN FROM HEAVEN</h2>
                <h6>Year, 1988</h6>
            </header>
            <div className="image-container">
                <img src="https://firebasestorage.googleapis.com/v0/b/sij-dev.appspot.com/o/about_us%2Fheaven_light.png?alt=media&token=4d7a3d70-497c-4f85-8911-f519e3e885a8" alt="blog post" />
            </div>
            <p>It was a fine ordinary day of the year 1988. I was in my room, my eyes closed, my heart longing for the Holy Spirit. 
                My spiritual eyes were looking at the heavenly light that was descending from heaven. 
                I saw glowing, glittering, large white letters coming down from heaven. I was filled with wonder as I gazed upon the letters. 
                I read it: SPIRIT IN JESUS and the letters disappeared shortly after.</p>
            <br></br>
            <p>The next day, while I was in my personal prayer, I saw the same dazzling, glittering letters coming down 
                from heaven. I read them again; "SPIRIT IN JESUS". This experience repeated on another day, the same glowing, 
                glittering letters appearing from heaven before my eyes. It was the same: SPIRIT IN JESUS. 
                This confirmed to me that there was something significant about the name.Taking a notebook I wrote on the 
                first page - "SPIRIT IN JESUS"..
            </p>
            {/* <blockquote>
            <p>This is a special quote with a larger font size.</p>
        </blockquote> */}
            <blockquote className="multi-color-quote">
                <span className="blue">I saw glowing, glittering, large white letters coming down from heaven. I was filled with wonder as I gazed upon the letters.</span>
                <span className="red"> I read it: SPIRIT IN JESUS.</span>
            </blockquote>
            <p>This experience took place in 1988, after I attended my first charismatic renewal retreat. 
                My participation in the retreat was like grasping at a straw. It was my last hope in life. 
                I went for the retreat with the sole purpose of determining the existence of God. 
                Despite growing up in a Catholic family and receiving a strong training in our 
                Catholic faith by my mother, father, and grandmother, I had lost my faith due to various hardships and challenges that 
                I faced in my thirties. I put my trust in God and prayed, but there was no answer. 
                I realised I was losing my faith. God was not answering any of my prayers. 
                I cried and lamented before the Lord, but there was no reply. I convinced myself that my unanswered prayers 
                were evidence of the non-existence of God, and subsequently, I began to doubt the divinity of Jesus, 
                considering him only a human being. This led me to abandon my prayer practices and even lost interest in going for Holy Mass.</p>
            <br></br>
            <p>The devil in my mind told me, ‘if there is no God, then why should you live ?’ This thought consumed me, 
                and I became fixated on the idea of how to die. At the same time I was concerned about my family, 
                my children. What would happen to them if I die ? It was then that the right time of God dawned. 
                The merciful Lord in his right time stretched out his right hand and pulled me out of the shadows of death. 
                The Lord says, “Surely I know the plans I have for you, says the Lord, plans for your 
                welfare and not for harm’’ (Jeremiah 29:11))</p>
            <br></br>
            <div className="video-container">
                    <YouTube videoId="iQhEvdLj8Y0" opts={opts}/>
            </div>
        </article>;
    }

    function iSawJesus() {
        return <article id="iSawJesus">
            <header>
                <h2>I SAW JESUS</h2>
                <h6>Charismatic retreat, Feb 28 - March 4, 1988</h6>
            </header>
            <div className="image-container">
                <img src="https://firebasestorage.googleapis.com/v0/b/sij-dev.appspot.com/o/about_us%2Fjesus_i_saw_v3.jpeg?alt=media&token=29200c46-720d-47d4-94b2-bfd611425b7f" alt="blog post" />
            </div>
            <p>In 1988, God orchestrated a wonderful plan for my redemption through my elder sister. 
                I attended a Charismatic renewal retreat at Christ Hall in Calicut, Kerala, 
                India with the realization that this was my final opportunity to get saved. 
                I said to myself, ‘If there truly is a God, I want to meet Him and seek answers as to why my prayers went unanswered.’
            </p>
            <p>
                <br />
                The retreat began on the 29th of February 1988. On March 2nd evening, while I was praising God, 
                together with the group at around 9:00 PM, something happened to me. I felt as if a burning coal fell upon my tongue. 
                I lost the control of my tongue and started to praise God in a different manner. I was not able to control myself. 
                It was like water gushing out of a dam when its shutters were opened. Eventually, the prayer session ended, 
                and everyone went to their respective rooms to rest.
            </p>
            <blockquote className="multi-color-quote">
                <span className="blue">I saw Jesus standing just in front of me; both his hands
                    were stretched out and</span>
                <span className="red">" I saw heavy light gushing out of his hands"</span>
            </blockquote>
            <p>I hurried to the chapel, which was a small room, where I knelt and again began to praise. 
                After everyone left, I opened my mouth and praised God loudly in a different language. 
                Suddenly, something amazing happened. I saw Jesus standing just in front of me; both his 
                hands were stretched out and I saw heavy light gushing out of his hands. My hands started to shiver. 
                I looked at Jesus with awe and wonder. I looked at his eyes. I saw him very closely. I was so excited. 
                Never did I expect an encounter with Jesus. I was seeing the one whom I had rejected, about whom 
                I had said he is not God but only a human being. He didn’t speak anything and then he disappeared. 
                Today, when I reflect on those precious moments I remember his words, “I am the light of the world. 
                Whoever follows me will never walk in darkness, but will have the light of life’’ (John 8:12). Yes!, 
                Jesus was revealing to me that he is the true light. He is the light of this world and was asking me 
                to follow him.</p>
            <Article text="As Jesus disappeared, suddenly there came Mother Mary in her blue robe" />
        </article>;
    }

    function whatIsSpritiInJesus() {
        return <article id="whatIsSpiritInJesus">
            <header>
                <h2>WHAT IS SPIRIT IN JESUS?</h2>
                <h6>The Anointing that Jesus received from Heaven.</h6>
            </header>
            <div className="image-container">
                <img src="https://firebasestorage.googleapis.com/v0/b/sij-dev.appspot.com/o/about_us%2Fbaptism.gif?alt=media&token=264be187-6f2c-411d-8206-d68e70290301" alt="blog post" />
            </div>
            <p>"Spirit in Jesus" is not the same as "Spirit OF Jesus'. "Spirit OF Jesus" is Jesus himself, 
                the spirit or soul of Jesus. We read in the book of Acts 16:7 about the ‘Spirit of Jesus’. 
                At the time of his transfiguration Jesus revealed himself to the three disciples. 
                They saw Jesus as the perfect God. “His face shone like the sun and his clothes 
                became dazzling white’’ (Matthew 17:2). Really Jesus was revealing to them his identity, his soul or spirit. 
                But what is Spirit IN Jesus? I couldn’t understand the meaning of that for a long time. 
                It took more than twenty years for me to understand ‘Spirit In Jesus’. God wanted a long 
                time to reveal the meaning. He waited for my sanctification.
            </p>
            <br></br>
            <p> ‘Spirit in Jesus’ is the anointing that Jesus received from heaven, at the time of his baptism in 
                the river Jordan. John the prophet baptised Jesus with water. “Suddenly the heavens were 
                opened and the Spirit of God descended upon him like a dove’’ (Mt 3:16- 17). God the Father anointed 
                Jesus with the Holy Spirit, the third person in the Holy Trinity. Although Jesus was born through 
                the Holy Spirit in the womb of the Virgin Mary, he had to receive this special anointing from 
                the Father to commence his public life. That anointing of the Holy Spirit is known as the Spirit in Jesus. 
                The Spirit in Jesus empowered Jesus to perform the miracles - to heal the sick, to cast out the 
                evil spirits and demons and to raise the dead. Also the Spirit in Jesus gave him the power 
                to suffer and die on the cross.</p>
            <blockquote className="multi-color-quote">
                <span className="red">‘Spirit in Jesus’ is the anointing that Jesus received from heaven, at the time of his
                    baptism in the river Jordan.</span>
                <span className="blue"> Bible affirms that Jesus was anointed: “.....how God anointed Jesus of Nazerath
                    with the Holy Spirit and with power, how he went about doing good and healing all who
                    were oppressed by the devil, for God was with him’’ (Acts 10:38)</span>
            </blockquote>
            <div className="image-container">
                <img src="https://firebasestorage.googleapis.com/v0/b/sij-dev.appspot.com/o/about_us%2Fholy_cross.jpeg?alt=media&token=5b8c92d8-154a-44fd-973a-8720fd3d6950" alt="blog post" />
            </div>
            <p>In spirit, Jesus descended into hell and preached the gospel to the spirits imprisoned 
                there (I pet 3:19; the Creed). He resurrected from the dead on the third day with the 
                power of the Spirit in him. With the same power Jesus ascended into heaven and is seated at 
                the right hand of the Father and he will come again to judge the world. The Bible affirms that Jesus 
                was anointed: “.....how God anointed Jesus of Nazareth with the Holy Spirit and with power, 
                how he went about doing good and healing all who were oppressed by the devil, for 
                God was with him’’ (Acts 10:38). Again in the same book (Acts 4:27) we read ‘God anointed Jesus’. 
                Anointing is the supernatural power of God. Jesus was anointed with the supernatural power of God. 
                Jesus received that anointing as God’s power. Today that anointing is known in Jesus’ name. 
                That is ‘Spirit in Jesus</p>
            <blockquote className="multi-color-quote">
                <span className="red">In spirit, Jesus descended into hell and preached the gospel to the spirits imprisoned 
                there (I pet 3:19; the Creed).</span>
            </blockquote>
        </article>;
    }

    function spiritInJesusForLastDays() {
        return <article id="spiritInJesusForLastDays">
            <header>
                <h2>SPIRIT IN JESUS FOR THE LAST DAYS</h2>
                <h6>I saw the Lord always before me.</h6>
            </header>
            <div className="image-container">
                <img src="https://firebasestorage.googleapis.com/v0/b/sij-dev.appspot.com/o/about_us%2Flast_anointing_img.png?alt=media&token=79e46d84-f194-46f9-81b4-0268852aa95b" alt="blog post" />
            </div>
            <p>On March 4th, 1988, God anointed me with the ‘Spirit in Jesus' anointment, but at the time 
                I did not fully comprehend its significance. It was only twenty years later I began to 
                understand that God has anointed me with the Spirit in Jesus' anointment. When I saw the name coming down 
                from heaven, I didn’t understand it. Sometimes I would hear someone calling out ‘Spirit in Jesus’. 
                I would turn around to see who it was. I saw none near me.
            </p>
            <br></br>
            <p> During those days, I saw a clear face with bright eyes in front of me, and I pondered whether 
                it was me looking at my own face. I double-checked multiple times. To confirm this, 
                I even covered my eyes with thick cotton cloth to determine the face with the shining eyes that 
                I had seen before me, still it was not my face. Everytime I could see the shining eyes with a penetrating look. 
                “I saw the Lord always before me. For he is at my right hand, so that I will not be shaken’’ (Acts 2:25, Ps 16:8). 
                Even today I see him just in front of me. Additionally I have seen a woman full of light. 
                I understood it was Mother Mary. Every day I could see her.
            </p>
            <blockquote className="multi-color-quote">
                <span className="red">‘I saw the Lord always before me. For he is at my right hand, so that I will not be shaken’’ (Acts 2:25, Ps 16:8)</span>
                <span className="blue">Even today I see him just in front of me, Also the presence of Mother Mary</span>
            </blockquote>
            <p>Jesus and Mary were with me always. I wondered why. Today I know how the Lord was protecting 
                the anointing of “Spirit in Jesus’’ in me. In times of tribulations in the beginning of my spiritual 
                life, Jesus and Mary stayed with me. Sometimes the angels appeared. Oh Lord, my God! How you encompassed 
                me because of the anointment! The Anointing of Spirit in Jesus completed 35 years on March 4th 2023. 
                I now praise you Father in heaven, I thank you Lord Jesus, I thank you Holy Spirit and 
                I thank you holy Virgin Mary. I thank the angels, the prophets, the apostles and all the saints 
                for all the blessings.
            </p>
        </article>;
    }

    function lastAnointing() {
        return <article id="lastAnointing">
            <header>
                <h2>THE LAST ANOINTING</h2>
                <h6>"I will pour out my Spirit upon all flesh."</h6>
            </header>
            <div className="image-container">
                <img src="https://firebasestorage.googleapis.com/v0/b/sij-dev.appspot.com/o/about_us%2Fanointing_all.png?alt=media&token=6111134c-748f-4b05-b9ec-3ca2cc756bdf" alt="blog post" />
            </div>
            <p>Spirit in Jesus Anointing is the last anointment that God is going to pour out upon all flesh, 
                as prophesied by St.Peter: “In the last days it will be, God declares, that I will pour out my Spirit 
                upon all flesh, and your sons and your daughters shall prophesy, and your young men shall see visions, 
                and your old men shall dream dreams. Even upon my slaves, both men and women, in 
                those days I will pour out my Spirit; and they shall prophesy’’ (Acts 2:17-18).
            </p>
            <blockquote className="multi-color-quote">
                <span className="red">‘as prophesied by St.Peter : </span>
                <span className="blue">In the last days it will be, God declares, that I will pour out my
                    Spirit upon all flesh, and your sons and your daughters shall prophesy, and your young men shall see visions,
                    and your old men shall dream dreams. Even upon my slaves, both men and women,
                    in those days I will pour out my Spirit; and they shall prophesy (Acts 2:17-18)</span>
            </blockquote>
        </article>;
    }

    function recieveAnointing() {
        return <article id="howToReceiveAnointing">
            <header>
                <h2>RECEIVE THE ANOINTING</h2>
                <h6>"Born of the Spirit."</h6>
            </header>
            <div className="image-container">
                <img src="https://firebasestorage.googleapis.com/v0/b/sij-dev.appspot.com/o/about_us%2Freceive_anointing.png?alt=media&token=f4db0e6e-1e3c-4e45-9d98-3d516cc52ead" alt="blog post" />
            </div>
            <p>How can you receive the anointing of Spirit in Jesus? You can receive the anointing of 
                Spirit in Jesus by going through a seven-day residential retreat and becoming "born of the Spirit.". 
                This retreat will give you new life through the Spirit in Jesus anointing. “Those who have been born of 
                God do not sin, because God’s seed abides in them; they cannot sin, because they have been born of God’’ 
                (I John 3:9).
            </p>
            <blockquote className="multi-color-quote">
                <span className="blue">(I Jn 3:9) : </span>
                <span className="red">Those who have been born of God do not sin, because God’s
                    seed abides in them; they cannot sin, because they have been born of God’’</span>
            </blockquote>
        </article>;
    }
};

export default AboutUs;
